import React from 'react'
import { Web3Spaces } from '../components/Web3Spaces'

export function Web3() {
    return (
        <>
            <h1>All Spaces On Chain</h1>
            <Web3Spaces />
        </>
    )
}
