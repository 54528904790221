import React from 'react'
import { WalletLinking } from '@components/WalletLinking'
export function WalletLinkingPage() {
    return (
        <>
            <h1>Linked Wallets</h1>
            <WalletLinking />
        </>
    )
}
